import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import lodash from "lodash"

import VueMoment from "vue-moment"
import VueI18n from "vue-i18n";
import VueCookies from "vue-cookies"
import Notifications from "vue-notification"
import {BootstrapVue} from "bootstrap-vue"
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import axios from "axios"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from "@fortawesome/fontawesome-svg-core"

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowUp,
  faBars,
  faBolt,
  faCaretSquareDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCloud,
  faCloudUploadAlt,
  faCog,
  faColumns,
  faDatabase,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileImport,
  faFolderOpen,
  faGlobe,
  faHdd,
  faInfoCircle,
  faKey,
  faLanguage,
  faMemory,
  faMicrochip,
  faMinus,
  faMinusCircle,
  faNetworkWired,
  faPaperPlane,
  faPause,
  faPauseCircle,
  faPen,
  faPlay,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faQuestionCircle,
  faRedo,
  faSearch,
  faShare,
  faShieldAlt,
  faSpinner,
  faStar,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
  faUpload,
  faUser,
  faUserCheck,
  faUserCog,
  faUserPlus,
  faUserShield,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons"

import i18n from "@/i18n"
import {tokenInterceptor} from "@/api/utils";

library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAlt,
  faArrowUp,
  faBars,
  faBolt,
  faCaretSquareDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCloud,
  faCloudUploadAlt,
  faCog,
  faColumns,
  faDatabase,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileImport,
  faFolderOpen,
  faGlobe,
  faHdd,
  faInfoCircle,
  faKey,
  faLanguage,
  faMemory,
  faMicrochip,
  faMinus,
  faMinusCircle,
  faNetworkWired,
  faPaperPlane,
  faPause,
  faPauseCircle,
  faPen,
  faPlay,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faQuestionCircle,
  faRedo,
  faSearch,
  faShare,
  faShieldAlt,
  faSpinner,
  faStar,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlink,
  faUpload,
  faUser,
  faUserCheck,
  faUserCog,
  faUserPlus,
  faUserShield,
  faUserSlash
)

Vue.component("fa-icon", FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Notifications)
Vue.use(VueMoment)
Vue.use(VueI18n)
Vue.use(VueCookies)

// axios as default http client
const http = axios.create({
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken"
})

http.interceptors.request.use(tokenInterceptor)

Vue.prototype.$http = http

Vue.prototype.$_ = lodash

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
