<template>
  <b-container class="mt-5 px-5">
    <b-row class="mt-5">
      <b-col>
        <h1>Proposer un échange</h1>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col cols="12">
        <div class="text-muted" v-if="barter._jv">Barter: {{ barter._jv.id }}</div>
        <div class="text-muted">Barter state: {{ barter.state }}</div>
        <div class="text-muted">Offers made in this barter: {{ $_.size(barter.offers) }}</div>
        <div class="text-muted">Active offer #1: {{ parties[0].offerId }}</div>
        <div class="text-muted">Active offer #2: {{ parties[1].offerId }}</div>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" cols="12" align="center">
        <main-search
            v-if="parties[1].adding"
            v-on:resource-selected="addResource(1, $event)">
        </main-search>
        <b-card v-else title="Votre proposition">
          <b-card-text class="pt-3">
            <b-list-group>
              <b-list-group-item v-for="resource in parties[0].resources">
                <span>{{ resource.title }}</span>
                &nbsp;
                <span class="text-muted">#{{ resource._jv.id }}</span>
              </b-list-group-item>
            </b-list-group>
            <span class="text-muted" v-show="$_.isEmpty(parties[0].resources)">
              Aucun produit ou service séléctionné
            </span>
          </b-card-text>
          <b-btn :variant="parties[0].adding ? 'warning' : 'success'" block
                 @click="parties[0].adding = !parties[0].adding">
            <span v-if="parties[0].adding">
              Valider ma sélection
            </span>
            <span v-else>
              Ajouter un produit ou un service
            </span>
          </b-btn>
        </b-card>
      </b-col>
      <b-col lg="6" cols="12" align="center">
        <main-search
            v-if="parties[0].adding"
            v-on:resource-selected="addResource(0, $event)">
        </main-search>
        <b-card v-else title="Offre de l'autre partie">
          <b-card-text class="pt-3">
            <b-list-group>
              <b-list-group-item v-for="resource in parties[1].resources">
                <span>{{ resource.title }}</span>
                &nbsp;
                <span class="text-muted">#{{ resource._jv.id }}</span>
              </b-list-group-item>
            </b-list-group>
            <span class="text-muted" v-show="$_.isEmpty(parties[1].resources)">
              Aucun produit ou service séléctionné
            </span>
          </b-card-text>
          <b-btn :variant="parties[1].adding ? 'warning' : 'success'" block
                 @click="parties[1].adding = !parties[1].adding">
            <span v-if="parties[1].adding">
              Valider ma sélection
            </span>
            <span v-else>
              Ajouter un produit ou un service
            </span>
          </b-btn>
        </b-card>
      </b-col>
      <b-col cols="12" class="mt-2">
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col cols="12" align="right">
        <b-btn-group>
          <b-btn variant="warning" @click="ready()">
            Valider et proposer cet échange
          </b-btn>
        </b-btn-group>
      </b-col>
      <b-col cols="12" align="right" class="mt-2">
        <b-btn-group>
          <b-btn variant="success" @click="trigger('first_party_accepted_barter')">
            Accepter cet échange #1
          </b-btn>
          <b-btn variant="success" @click="trigger('second_party_accepted_barter')">
            Accepter cet échange #2
          </b-btn>
        </b-btn-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
    </b-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import MainSearch from "@/views/MainSearch";
import {mapGetters} from "vuex";

export default {
  name: "BarterPage",
  components: {MainSearch, PageTitle},
  data() {
    return {
      barterId: 1,
      parties: [
        {
          offerId: undefined,
          resources: [],
          adding: false
        },
        {
          offerId: undefined,
          resources: [],
          adding: false
        },
      ]
    }
  },
  created() {
    const params = {
      include: "offers"
    }
    this.$store
        .dispatch("api/get", [`barters/${this.barterId}`, {params: params}])
        .then()
  },
  methods: {
    addResource(party, resource) {
      console.debug(`Adding to party ${party} resource`, resource)
      this.parties[party].resources.push(resource)
    },
    ready() {
      for (let party of this.parties) {
        let offer = this._buildOffer(this.barter, this.user, party.resources)
        this.$store.dispatch("api/post", [offer])
            .then(offer => party.offerId = offer._jv.id)
      }
      this.trigger("first_party_offer_ready")
    },
    trigger(barterEvent) {
      let params = {
        event: barterEvent
      }
      // (this works, but going through jsonapi-vuex actions might be overkill)
      this.$store.dispatch("api/patch", [this.barter, {
        url: `barters/${this.barter._jv.id}/trigger`,
        params: params
      }])
      .then()
    },
    _buildOffer(barter, user, resources) {
      return {
        _jv: {
          type: "offers",
          relationships: {
            barter: {
              data: {
                type: "barters",
                id: barter._jv.id
              }
            },
            party: {
              data: {
                type: "users",
                id: user._jv.id
              }
            },
            resources: {
              data: resources.map(resource => ({
                type: "resources",
                id: resource._jv.id
              }))
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["user", "loggedIn"]),
    barter() {
      return this.$store.getters["api/get"](`barters/${this.barterId}`)
    }
  }
}
</script>

<style scoped>

</style>