import * as HttpStatus from "http-status-codes";
import Vue from "vue";

export { HttpStatus };

export const TOKEN_COOKIE_NAME = 'exchtkk'

export const handleServiceError = (serviceName, error) => {
  // console.error(error);
  const response = error.response;
  let detail = error.message;
  let type = "error";
  if (response) {
    if (response.status < 500) type = "warn";
    if (response.status === HttpStatus.FORBIDDEN) {
      detail = "You do not have permission to perform this action.";
    } else if (response.status === HttpStatus.BAD_REQUEST) {
      detail = "The request you made is incorrect.";
    } else if (
      [
        HttpStatus.BAD_GATEWAY,
        HttpStatus.GATEWAY_TIMEOUT,
        HttpStatus.REQUEST_TIMEOUT,
      ].includes(response.status)
    ) {
      detail =
        "The service could not be contacted. " +
        "This may be a temporary network issue or the service may be down.";
    } else {
      detail = `${response.statusText} (${response.status})`;
    }
    if (response.data) {
      if (response.data.detail) {
        detail = `${response.data.detail}`;
      } else if (response.data.errors) {
        for (error of response.data.errors) {
          detail = error.detail;
        }
      }
    }
  }
  const title = type === "error" ? `Error` : `Warning`;
  Vue.notify({
    group: "global",
    type: type,
    title: title,
    duration: 20000, // in ms, -1 = no duration
    text: detail,
  });
  return response;
};

export const tokenInterceptor = (config) => {
  const token = Vue.$cookies.get(TOKEN_COOKIE_NAME)
  if (token) {
    config.headers.Authorization = `Token ${token}`
  }
  return config
}