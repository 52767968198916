<template>
  <div id="app">
    <notifications position="bottom center" group="global"/>
    <nav-bar></nav-bar>
    <b-container class="m-0 p-0" fluid>
      <b-row no-gutters>
        <b-col cols="12">
          <router-view/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/share/NavBar"

export default {
  components: {NavBar},
  data() {
    return {
      version: undefined,
      build: undefined,
    };
  },
  created() {
    // Set user id if authenticated. If not authenticated, this will implicitly fails,
    // and the user will have to login through the LoginForm.
    this.$store.dispatch("setUserId").catch(_ => {})
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/default/default.scss";

.bg-cover {
  background-color: grey !important;
  background-size: cover;


  .navbar {
    .navbar-brand, .navbar-brand:active, .navbar-brand:focus, .navbar-brand:hover {
      color: white;
    }

    .navbar-nav {
      .nav-link {
        color: white !important;
      }
    }

    background: transparent !important;
  }
}

</style>
