import Vue from "vue"
import VueRouter from "vue-router"
import Home from "@/views/Home.vue"
import PageNotFound from "@/views/PageNotFound"
import MyAccountPage from "@/views/MyAccountPage"
import MainSearch from "@/views/MainSearch"
import RestrictedHome from "@/views/RestrictedHome"
import BarterPage from "@/views/BarterPage"
import store from "@/store"

Vue.use(VueRouter)

const searches = [
  {
    path: "/s",
    name: "search:main",
    component: MainSearch
  }

]

const users = [
  {
    path: "/account",
    name: "account:page",
    component: MyAccountPage
  }
]

const barters = [
  {
    path: "/barters",
    name: "barters:page",
    component: BarterPage
  }
]

const routes = [
  ...users,
  ...searches,
  ...barters,
  {
    path: "/",
    alias: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/welcome",
    name: "home:restricted",
    component: RestrictedHome
  },
  {
    path: "*",
    component: PageNotFound,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

const UNRESTRICTED_ROUTES = ["home"]

export const isRestrictedRoute = routeName => !UNRESTRICTED_ROUTES.includes(routeName)

router.beforeEach((to, from, next) => {
  if (isRestrictedRoute(to.name) && !store.getters.loggedIn) {
    next({name: "home"})
  }
  next()
})


export default router
