import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import {jsonapiModule} from "jsonapi-vuex"
import {paramsSerializer} from "@/utils"
import {tokenInterceptor} from "@/api/utils"
import auth from "@/store/auth"


Vue.use(Vuex)

const baseURL = process.env.VUE_APP_API_BASE_URL

Vue.prototype.$baseURL = baseURL

// This configuration for the jsonapi modules must be shared.
// The upstream library reference the configuration globally, for all modules.
// Therefore it is impossible to use different version for different modules.
let jsonapiConf = {
  preserveJson: true,
  clearOnUpdate: false,
  // only unique or modified attributes are kept
  // cleanPatch: true,
  // we still keep relationships when doing the clean patch
  cleanPatchProps: ["relationships"],
}

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/vnd.api+json",
  },
  paramsSerializer: paramsSerializer,
})

api.interceptors.request.use(tokenInterceptor)

export default new Vuex.Store({
  state: {
    dev: false,
  },
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    auth: auth,
    api: jsonapiModule(api, jsonapiConf),
  },
})

