<template>
  <b-row
    :class="{ divider: !noDivider }"
    class="page-title"
    align-v="end"
    no-gutters
  >
    <b-col cols="12">
      <slot name="title">
        <span>
          <span class="title">{{ title }}</span>
          <fetching
            v-show="!noFetching"
            class="align-offset"
            :fetching="fetching"
          ></fetching>
          <loading class="align-offset" small :loading="loading"></loading>
        </span>
      </slot>
      <span class="float-right">
        <slot name="toolbar"></slot>
      </span>
      <slot name="subtitle">
        <h5 class="subtitle">{{ subtitle }}</h5>
      </slot>
    </b-col>
  </b-row>
</template>

<script>
import Loading from "@/components/share/Loading";
import Fetching from "@/components/share/Fetching";

export default {
  name: "PageTitle",
  components: { Loading, Fetching },
  props: {
    title: String,
    subtitle: String,
    noDivider: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: undefined,
    },
    noFetching: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/default.scss";

.page-title {
  padding-bottom: 0.7rem;
}

.title {
  font-weight: bold;
  font-size: $font-size-base * 2;
}

.align-offset {
  // offset to better align the loading spinner with the title
  position: absolute;
  top: 0.6rem;
  margin-left: 0.5rem;
}
</style>
