<template>
  <b-form @submit="login">
    <b-form-row class="pt-0 pb-2 px-2 m-0">
      <b-col cols="12" align="center" class="mb-4">
        <h5><span class="font-weight-bold"><b>Inscrivez-vous</b></span></h5>
      </b-col>
      <b-col cols="12">
        <p>
          Pour recevoir une invitation d'inscription merci d'effectuer une demande
          auprès de
          <a href="maito:contact@hayolabs.com">contact@hayolabs.com</a>!
        </p>
      </b-col>

    </b-form-row>
  </b-form>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      email: undefined,
      password: undefined
    }
  },
  methods: {
    login(e) {
      e.preventDefault()
      console.debug("Logging in...")
    }
  }
}
</script>

<style lang="scss" scoped>

input {
  height: 3.5rem;
}

button[type=submit] {
  height: 3.5rem;
}

</style>