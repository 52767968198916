<template>
  <b-container class="my-5">
    <b-row class="py-5 my-5">
      <b-col lg="12" align="center">
        <h1>
          <span>Bienvenue sur</span>&nbsp;
        </h1>
      </b-col>
      <b-col lg="12" align="center">
        <large-logo></large-logo>
      </b-col>
      <b-col cols="12" align="center">
        <h1 class="my-4">
          Veuillez entrer votre code d'invitation pour accéder à la plateforme:
        </h1>
      </b-col>
      <b-col cols="12">
        <b-input-group class="py-2">
          <b-form-input placeholder="Code d'accés"></b-form-input>
          <b-input-group-append>
            <b-button variant="dark">Valider</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MainSearchForm from "@/components/forms/MainSearchForm"
import MainLogo from "@/components/MainLogo"
import LargeLogo from "@/components/LargeLogo"

export default {
  name: "RestrictedHome",
  components: {MainLogo, MainSearchForm, LargeLogo},
};
</script>

<style lang="scss" scoped>
input {
  height: 3.5rem;
  border-radius: 0.75rem;
}

button {
  border-radius: 0.75rem;
}
</style>