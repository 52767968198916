<template>
  <span v-show="loading">
    <b-spinner variant="pending" :small="small" label="Loading"></b-spinner>
    <span class="loading-text">{{ text }}</span>
  </span>
</template>

<script>
export default {
  name: "Loading",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/default/default.scss";

.loading-text {
  color: gray("600");
  padding-top: 15px;
  font-size: 1rem;
}
</style>
