<template>
  <b-container>
    <b-row>
      <b-col align="center">
        <div class="home">
          <h1>This page does not exist</h1>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
