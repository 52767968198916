import Vue from "vue";
import {TOKEN_COOKIE_NAME} from "@/api/utils";

export default {
  // this module is not namespaced and relies on jsonapi-vuex to store api data!
  state: () => ({
    userId: undefined
  }),
  mutations: {
    setUserId(state, userId) {
      console.debug("setUserId", userId)
      state.userId = userId
    }
  },
  actions: {
    setToken(context, {token}) {
      // we don't store token in the store (localStorage) but in cookies,
      // as it is more secure.
      Vue.$cookies.set(TOKEN_COOKIE_NAME, token)
    },
    removeToken() {
      Vue.$cookies.remove(TOKEN_COOKIE_NAME)
    },
    async setUserId({dispatch, commit}) {
      // we reset the user id, so that when rejoining the application,
      // if the user is not authenticated, we don't keep the "old" user in the store.
      commit("setUserId", undefined)
      let user = await dispatch("api/get", "users/current")
      // (if it fails to get current, the user will remain undefined)
      let userId = user._jv.id
      commit("setUserId", userId)
    },
    logout({dispatch, commit}) {
      dispatch("removeToken")
      commit("setUserId", undefined)
    }
  },
  getters: {
    user: (state, getters) => {
      return getters["api/get"](`users/${state.userId}`)
    },
    loggedIn: state => {
      return state.userId !== undefined
    },
    token: _ => {
      return Vue.$cookies.get(TOKEN_COOKIE_NAME)
    }
  }
}