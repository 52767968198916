"use strict";
var utils = require("axios/lib/utils");

export function removeJsonApiMeta(response) {
  const data = { ...response, _jv: undefined };
  delete data._jv;
  return data;
}

/**
 * Axios enconding, except brackets.
 */
function encode(val) {
  return encodeURIComponent(val)
    .replace(/%3A/gi, ":")
    .replace(/%24/g, "$")
    .replace(/%2C/gi, ",")
    .replace(/%20/g, "+");
}

/**
 * Override of axios paramsSerializer, to bypass brackets encoding,
 * that axios may address in https://github.com/axios/axios/issues/3316.
 * This implementation is the same as axios default, except brackets are
 * not replaced in the encode() function:
 * https://github.com/axios/axios/blob/v0.21.1/lib/helpers/buildURL.js
 */
export function paramsSerializer(params) {
  let parts = [];
  let serializedParams;
  utils.forEach(params, function serialize(val, key) {
    if (val === null || typeof val === "undefined") {
      return;
    }

    if (utils.isArray(val)) {
      key = key + "[]";
    } else {
      val = [val];
    }

    utils.forEach(val, function parseValue(v) {
      if (utils.isDate(v)) {
        v = v.toISOString();
      } else if (utils.isObject(v)) {
        v = JSON.stringify(v);
      }
      parts.push(encode(key) + "=" + encode(v));
    });
  });
  serializedParams = parts.join("&");
  return serializedParams;
}

export function slugify(str, sep = "_") {
  // Source: https://gist.github.com/mathewbyrne/1280286
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/-,:;";
  var to = "aaaaeeeeiiiioooouuuunc______";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9_ ]/g, "") // remove invalid chars
    .replace(/\s+/g, sep); // collapse whitespaces and replace with _
  return str;
}

export function pluralize(word, count = 2) {
  // default of count set to 2 to automatically pluralize.
  // obviously this function should be carefully used as it is a dummy suffixer,
  // which fits current use cases.
  // Nonetheless, this function can be changed to support more cases, for example:
  // https://github.com/blakeembrey/pluralize
  // https://stackoverflow.com/questions/27194359/javascript-pluralize-an-english-string
  if (Math.abs(count) <= 1) {
    return word;
  } else {
    if (word.endsWith("s")) return `${word}es`;
    if (word.endsWith("y")) return `${word.slice(0, -1)}ies`;
    else return `${word}s`;
  }
}

export function capitalize(value) {
  if (!value) {
    return "";
  }
  return value.toString().charAt(0).toUpperCase() + value.slice(1);
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
