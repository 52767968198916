<template>
  <span class="brand">
  <span style="color: #eebf11;">.</span>
  <span style="color: #eebf11;">be</span>
  <span style="color: #464646;">barter</span>
  </span>
</template>

<script>
export default {
  name: "MainLogo"
}
</script>

<style lang="scss" scoped>

.brand {
  font-family: 'Open Sans', sans-serif;
  padding: 10px;
  background: rgba(255, 255, 255, 0.12) !important;
}

</style>