<template>
  <b-container>
    <page-title title="Mon compte">
    </page-title>
    <b-row class="mt-3">
      <b-col cols="12">
        Your id is #{{ user._jv.id }}
      </b-col>
    </b-row>
    <b-row class="my-4">
      <b-col cols="12">
        <b-card title="Mes organisations">
          <b-card-text>
            <b-list-group>
              <b-list-group-item v-for="org in user.organizations">
                <span>{{ org.title }}</span>
                <b-badge class="ml-2" variant="info">{{ org.type.name }}</b-badge>
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PageTitle from "@/components/share/PageTitle";
import {mapGetters} from "vuex";

export default {
  name: "MyAccountPage",
  components: {PageTitle},
  created() {
    console.debug("Fetching user organizations..")
    let params = {
      "include": "organizations,organizations.type"
    }
    this.$store.dispatch("api/get", ["users/current", {params: params}])
        .then()
  },
  computed: {
    ...mapGetters(["user"]),
  }
}
</script>

<style scoped>

</style>