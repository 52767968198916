<template>
  <b-container class="px-5" fluid>
    <main-search-form></main-search-form>
    <b-row>
      <b-col>
        <div class="title-container mt-2">
          <h2>{{ $_.size(resources) }} résultats</h2>
        </div>
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col v-for="resource in resources" :title="resource.title" cols="12">
        <b-card
            no-body
            class="card"
        >
          <b-card-body>
            <b-card-title>
              <span>{{ resource.title }}</span>
              <b-badge class="ml-2">{{ resource.type}}</b-badge>
            </b-card-title>
            <b-button href="#" variant="outline-dark" @click="selectResource(resource)">
              Proposer un échange
            </b-button>
          </b-card-body>
          <template #footer>
            <span v-if="resource.rating === 0" class="text-muted">No rating</span>
            <span v-else>
              <small><fa-icon class="star mr-1" icon="star"></fa-icon></small>
              <small class="font-weight-bold">{{ resource.rating }}</small>
            </span>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MainSearchForm from "@/components/forms/MainSearchForm"

export default {
  name: "MainSearch",
  components: {MainSearchForm},
  data() {
    return {}
  },
  created() {
  },
  methods: {
    selectResource(resource) {
      console.debug("Selected resource:", resource)
      this.$emit("resource-selected", resource)
    }
  },
  computed: {
    resources() {
      return Object.values(this.$store.getters["api/get"]("resources"))
    },
  }
}
</script>

<style lang="scss" scoped>

.title-container {
  font-weight: bolder;
}

.star {
  color: orange;
}

.card {
  margin-bottom: 1rem;
  height: 13rem;
}

</style>