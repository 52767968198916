<template>
  <b-container
      class="items-top justify-center min-h-screen sm:items-center sm:pt-0 py-5"
      fluid
  >
    <b-row class="mb-5" align-content="center">
      <b-col lg="12" align="center">
        <large-logo></large-logo>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6" offset-xl="3" lg="8" offset-lg="2" cols="12" offset="0">
        <h1 class="text-justify pb-3">
          <b>
            Plateforme d'échange <br> entre professionnel belge.
          </b>
        </h1>
      </b-col>
      <b-col xl="6" offset-xl="3" lg="8" offset-lg="2" cols="12" offset="0" v-if="loggedIn">
        <main-search-form
            v-on:search-started="redirect()"
        ></main-search-form>

      </b-col>
      <b-col v-else xl="6" offset-xl="3" lg="8" offset-lg="2" cols="12" offset="0">
        <b-row>
          <b-col class="mt-3" cols="12">
            <h6>
              <p class="text-justify text-secondary">
                BE Barter est une plateforme numérique permettant de moderniser l’échange de biens
                ou de
                services sans contrepartie d’argent inter-entreprises. Le projet répond aux besoins
                sociaux, économiques et environnementaux. Il permet et encourage des modèles
                d’échange
                et de consommation alternatifs pour une société et une économie durable. BE Barter,
                à
                travers un réseau collaboratif, contribue au mouvement de diversification des modes
                de
                financement des entreprises en remettant le troc à la mode.
              </p>
            </h6>
          </b-col>
          <b-col class="mt-5 text-center" cols="10" offset="1">
            <h3>
              <p>
                Merci d'effectuer les demandes de présentation du projet
                auprès de <a href="mailto:contact@hayolabs.com">contact@hayolabs.com</a>
              </p>
            </h3>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<style lang="scss" scoped>

.soon {
  font-family: Poppins, sans-serif;
  color: rgba(255, 255, 255, 0.47);
  font-variant: all-small-caps;
}

.rocket {
  display: inline-block;
  width: 250px;
}

.title {
  font-family: Poppins, sans-serif;
  margin: 10px;
  color: white;
  font-variant: small-caps;
  font-weight: bold;
  font-size: 50px;
}

.contact-container {
  margin-top: 60px !important;
}

.contact {
  font-family: Poppins, sans-serif;
  margin: 10px;
  color: black;
  font-weight: bold;
  font-size: 30px;
}


.contact:focus {
  color: #faf884;
}

.contact:hover {
  color: #faf884;
}


.contact:active {
  color: #fffebe;
}

.yenatoufe {
  font-size: 7px;
  color: transparent;
}

.accessible {
  color: #2f744d;
}


</style>
<script>

import LargeLogo from "@/components/LargeLogo"
import MainSearchForm from "@/components/forms/MainSearchForm"
import {mapGetters} from "vuex"

export default {
  name: "Home",
  components: {LargeLogo, MainSearchForm},
  methods: {
    redirect() {
      this.$router.push({name: "search:main"})
    }
  },
  computed: {
    ...mapGetters(["user", "loggedIn"]),
  }
}
</script>