<template>
  <b-navbar
      ref="navbar"
      class="mb-3 bb-navbar-light"
      type="light"
      variant="light"
  >
    <b-modal id="login"
             ref="login"
             centered
             header-class="p-2 border-bottom-0"
             footer-class="p-2 border-top-0"
             hide-footer
    >
      <login-form
          @success="hideLoginModal"
      >
      </login-form>
    </b-modal>
    <b-navbar-brand :to="{name: 'home'}">
      <main-logo></main-logo>
    </b-navbar-brand>
    <b-navbar-nav class="ml-auto">
      <b-nav-item v-show="loggedIn"><b-badge variant="success">demo</b-badge></b-nav-item>
      <b-nav-form v-if="loggedIn">
        <b-button-group>
          <b-button squared size="sm" class="ml-2 py-2 my-sm-0" variant="outline-dark"
                    :to="{name: 'barters:page' }">
            Proposer un échange
          </b-button>
        </b-button-group>
      </b-nav-form>
      <b-nav-item-dropdown right v-if="loggedIn">
        <template slot="button-content">
          <fa-icon class="mr-2" icon="globe"></fa-icon>
          <span>{{ $i18n.locale }}</span>
        </template>
        <b-dropdown-item v-for="locale in availableLocales" @click="changeLocale(locale)">
          <span>{{ locale }}</span>
        </b-dropdown-item>
        <b-dropdown-item disabled>
          <span>nl (soon)</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown v-if="loggedIn" class="mr-1" right>
        <template v-slot:button-content>
          <fa-icon icon="user" class="mr-2"></fa-icon>
          <span>{{ user.username }}</span>
        </template>
        <b-dropdown-item :to="{name: 'account:page'}">Mon compte</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-b-modal.login>Logout</b-dropdown-item>
      </b-nav-item-dropdown>
      <!-- (not using v-else on purpose, the modal does not open after a logout,
            v-show to keep the element in the DOM, just hidden.) -->
      <b-nav-item v-show="!loggedIn" v-b-modal.login>Connexion</b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import LoginForm from "@/components/LoginForm";
import SignupForm from "@/components/SignupForm";
import MainLogo from "@/components/MainLogo";
import {mapGetters, mapState} from "vuex";

export default {
  name: "NavBar",
  components: {MainLogo, LoginForm, SignupForm},
  data() {
    return {
      availableLocales: ["en", "fr"]
    };
  },
  created() {
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
    },
    hideLoginModal() {
      this.$refs.login.hide()
    }
  },
  computed: {
    ...mapGetters(["user", "loggedIn"]),
    ...mapState(["dev"]),
    isHomeRoute() {
      return this.$route.name && this.$route.name.startsWith("home")
    },
  }
}
</script>

<style lang="scss" scoped>

@import "@/assets/scss/default/default.scss";

.bb-navbar-light {
  background-color: white !important;
  border-bottom: 1px solid $gray-200;

  .navbar-nav {
    .nav-link, .dropdown-toggle span {
      color: $gray-800 !important;
    }
  }
}

</style>


<i18n locale="en">
{
  "navbar": {
    "about": "About"
  }
}
</i18n>
<i18n locale="fr">
{
  "navbar": {
    "about": "À Propos"
  }
}
</i18n>
