<template>
  <b-form @submit="search">
    <b-form-row>
      <b-col cols="12">
        <b-input-group>
          <b-form-input
              v-model="value"
              placeholder="Chercher un produit ou un service"
          >

          </b-form-input>
          <b-input-group-append>
            <b-button type="submit" variant="dark">
              Rechercher
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-form-row>
  </b-form>

</template>

<script>

export default {
  name: "MainSearchForm",
  data() {
    return {
      value: "",
      loading: false,
      pageSize: 10,
      sortDesc: false,
    }
  },
  methods: {
    search(event) {
      event.preventDefault()
      console.debug("Searching:", this.value)
      this.$emit("search-started")
      this.fetchData({
        search: this.value
      })
          .then()
          .finally(() => this.loading = false)
    },
    async fetchData(filters = {}, page = 1) {
      this.loading = true;
      let params = {"page[number]": page, "page[size]": this.pageSize};
      if (this.sortBy) {
        let sort = this.sortBy;
        if (this.sortDesc) {
          sort = "-" + this.sortBy;
        }
        params["sort"] = sort;
      }
      if (filters.search) {
        params["filter[search]"] = filters.search;
      }
      await this._fetchData("resources", params)
      this.loading = false
    },
    async _fetchData(resourceType, params) {
      // Since we paginate, we need to keep in the store only the data from the requested page,
      // therefore we clear the data already present, probably from the previous pages.
      this.$store.commit("api/clearRecords", {_jv: {type: resourceType}});
      await this.$store.dispatch("api/get", [resourceType, {params: params}])
      // TODO this snippet is to handle pagination
      // .then(response => {
      //   if (this.paginate) {
      //     response = response._jv.json;
      //     this.pagination = response.meta.pagination;
      //     console.debug("Pagination update", response.meta.pagination);
      //   }
      // })
      // .catch(error => {
      //   this.error = true;
      //   handleServiceError(this.service, error);
      // })
      // .finally(() => (this.loading = false));
    },
  }
}
</script>

<style lang="scss" scoped>


input {
  height: 3.5rem;
}


</style>