<template>
  <b-form @submit="onSubmit">
    <b-form-row class="pt-0 pb-2 px-2 m-0">
      <b-col cols="12" align="center" class="mb-4">
        <h5>
          <span class="font-weight-bold">
            <b v-if="loggedIn">
              Déconnectez-vous
            </b>
            <b v-else>
              Connectez-vous
            </b>
          </span>
        </h5>
      </b-col>
      <b-col v-show="!loggedIn" cols="12">
        <b-row>
          <b-col v-if="dev" cols="12" class="dev text-muted mb-4" align="center">
            guest:guest
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-form-input
                  v-model="username"
                  type="text"
                  placeholder="Nom d'utilisateur"
                  :state="inputState('username')"
                  aria-describedby="username-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback v-if="errors['username']" id="username-live-feedback">
                {{ errors['username'].join(", ") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="password"
                  type="password"
                  placeholder="Mot de passe"
                  :state="inputState('password')"
                  aria-describedby="password-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback v-if="errors['password']" id="password-live-feedback">
                {{ errors['password'].join(", ") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-alert
                v-if="this.errors.nonFieldErrors && this.errors.nonFieldErrors.length > 0"
                variant="danger"
                show
            >
              {{ this.errors.nonFieldErrors.join(", ") }}
            </b-alert>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-button
            block
            type="submit"
            variant="dark"
            :disabled="loading"
        >
          <span v-if="loading"><b-spinner></b-spinner></span>
          <span v-else>
            <span v-if="loggedIn">
              Déconnexion
            </span>
            <span v-else>
              Connexion
            </span>
          </span>
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import {handleServiceError} from "@/api/utils"
import {mapGetters, mapState} from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      loading: false,
      username: undefined,
      password: undefined,
      errors: {
        nonFieldErrors: [],
        username: [],
        password: []
      }
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault()
      let func = this.loggedIn ? this.logout : this.login
      func()
    },
    login() {
      console.debug("Logging in...")
      // If we (force) login, we remove a potential token still in the browser cookies.
      // A remaining invalid token will make the API return a 401.
      this.$store.dispatch("removeToken")
      this.loading = true
      this.resetErrors()
      let apiTokenAuth = this.$baseURL + "/ata/"
      let data = {
        username: this.username,
        password: this.password
      }
      this.$http.post(apiTokenAuth, data)
          .then(response => {
            console.debug(`Logged in as ${this.username}. recvd token.`)
            const token = response.data.token
            this.$store.dispatch("setToken", {token})
            this.$store.dispatch("setUserId")
                .then(_ => {
                  this.$notify({
                    group: "global",
                    type: "success",
                    title: `You are logged in as ${this.user.username}`,
                    duration: 3000
                  })
                  this.$emit("success")
                })
                .catch(e => {
                  handleServiceError("api", e)
                })
          })
          .catch(e => {
            // this endpoint is not camelize (because not jsonapi),
            // so we camelize it here
            this.errors = e.response.data
            if (this.errors["non_field_errors"] !== undefined) {
              this.errors.nonFieldErrors = this.errors["non_field_errors"]
              delete this.errors["non_field_errors"]
            }
          })
          .finally(() => this.loading = false)
    },
    logout() {
      console.debug("Log out...")
      this.$store.dispatch("logout")
      this.$emit("success")
      this.$router.push({name: "home"})
    },
    inputState(field) {
      // Show only if server-side errors
      let error = this.errors[field]
      return error && error.length !== 0 ? false : null
    },
    resetErrors() {
      this.errors = {
        nonFieldErrors: [],
        username: [],
        password: []
      }
    }
  },
  computed: {
    ...mapGetters(["user", "loggedIn"]),
    ...mapState(["dev"])
  }
}
</script>

<style lang="scss" scoped>

input {
  height: 3.5rem;
}

button[type=submit] {
  height: 3.5rem;
}

</style>