<template>
        <span class="logo">
          <span class="hayo">.be</span>
          <span class="labs">barter</span>
        </span>
</template>

<script>
export default {
  name: "LargeLogo"
}
</script>

<style lang="scss" scoped>

.logo {
  .hayo {
    color: #eebf11;
    font-family: Ubuntu, sans-serif;
    font-weight: bold;
  }

  .labs {
    font-family: 'Open Sans', sans-serif;
    color: rgba(69, 69, 69, 0.95);
  }

  font-size: 150px;
  background-image: linear-gradient(120deg, #b7b09f 0%, #eebf11 100%);
  background-repeat: no-repeat;
  background-size: 88.2% 0.2em;
  background-position: 45% 85%;
  border-radius: 50px;
  transition: background-size 0.25s ease-in;
}

</style>